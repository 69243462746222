import "../includes/loginStyle/css/login-one.css";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, Navigate } from "react-router-dom";
import imm_logo from "../includes/images/imm_logo3.png";
import axios from "axios";
import { url, setUserInfo, setAjiraToken, setNin } from "../utility/Common";
import { Modal, Button, Form } from "react-bootstrap";
import Question_Modal from "./questionsmodal/Question_Modal";
import { createNotification } from "./Popup";
import pdf from "../includes/documents/User_Manual_For_Aplicant.pdf";
import loadingIcon from "../img/loadingElement.gif";
import { BiFontSize } from "react-icons/bi";

function Login({ setIsAuthenticated }) {
  const navigate = useNavigate();
  var md5 = require("md5");
  const [errors, setErrors] = useState({});
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [NIN, setNIN] = useState("");
  const [message, setMessage] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleCloseResetPassword = () => setShowResetPassword(false);
  const handleShowResetPassword = () => setShowResetPassword(true);
  const [loading, setLoading] = useState(false);

  const checkPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(Password);
  };

  //-------------------------------------------login
  const Login = {
    SystemId: "UHM005",
    ResourceType: "LOGIN",
    ManagementDataType: "ApplicantLogin",
    Username,
    Password: md5(Password),
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        Login
      );
      const { code } = response?.data;
      if (code == "1") {
        var IsEmV = response?.data?.data[0]?.IsEmV;
        var StageID = response?.data?.data[0]?.StageID;

        if (code == "1" && IsEmV == null) {
          console.log("if0", NIN);
          var userInfo = response?.data?.data[0];
          const token = response?.data?.token;
          setUserInfo(userInfo);
          setAjiraToken(token);
          setNin(response?.data?.data[0]?.NIN);

          var msg = response?.data?.message;
          const info = { type: "success", message: msg };
          createNotification(info)();
          setIsAuthenticated(true); // Update the authentication state
          navigate("/emailverification");
        } else if (code == "1" && IsEmV === 1 && StageID === "110") {
          var userInfo = response?.data?.data[0];
          const token = response?.data?.token;
          setUserInfo(userInfo);
          setAjiraToken(token);
          setNin(response?.data?.data[0]?.NIN);

          var msg = response?.data?.message;
          const info = { type: "success", message: msg };
          createNotification(info)();
          setIsAuthenticated(true); // Update the authentication state
          navigate("/home");
        } else if (code == "1" && IsEmV === 1) {
          var userInfo = response?.data?.data[0];
          const token = response?.data?.token;
          setUserInfo(userInfo);
          setAjiraToken(token);
          setNin(response?.data?.data[0]?.NIN);

          var msg = response?.data?.message;
          const info = { type: "success", message: msg };
          createNotification(info)();
          setIsAuthenticated(true); // Update the authentication state
          navigate("/educationbackg");
        }
      } else {
        const message = response?.data?.message;
        const info = { type: "error", message: message };
        createNotification(info)();
      }
    } catch (error) {
      console.log(error);
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    } finally {
      setLoading(false);
    }
  };

  //-------------------------------------------ForgetPassword
  const ForgetPassword = {
    SystemId: "UHM005",
    ResourceType: "askForToken",
    ManagementDataType: "ForgetPassword",
    NIN,
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        ForgetPassword
      );
      const { code } = response?.data;
      if (code == "1") {
        setMessage(response?.data?.message);
        handleCloseResetPassword();

        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
      } else {
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    } finally {
      setLoading(false);
    }
  };

  const openPDF = () => {
    window.open(pdf, "_blank");
  };

  /*-------------Styles--------------*/
  const styles = {
    linkContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingImage: {
      width: "20px",
      height: "20px",
    },
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="containerLogin">
          <div className="row login-box-12 col-md-12 pad-0">
            <div className="col-lg-5 col-md-12 col-sm-12 px-0">
              <div className="login_right">
                <a href="#" className="logo_text">
                  <img
                    src={imm_logo}
                    alt="Logo"
                    style={{
                      maxWidth: "80%",
                      height: "auto",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </a>

                <br />
                <Link className="btn-outline" onClick={openPDF}>
                  User Manual
                </Link>
                <ul className="social-list clearfix">
                  <Link className="btn-outline" onClick={() => setShow(true)}>
                    Guidelines!
                  </Link>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12">
              <div className="login-inner-form">
                <div className="details">
                  <h3>
                    IMMIGRATION DEPARTMENT
                    <br />
                    <span>RECRUITMENT PORTAL</span>
                  </h3>

                  <form className="form2" action="" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="NIN"
                        className="input-text"
                        placeholder="Username (NIN)"
                        onChange={(e) => setUsername(e.target.value)}
                        value={Username}
                        maxLength={20}
                        minLength={20}
                        title="NIN Format: 20010547641550000123"
                      />
                      {errors.NIN && (
                        <span className="text-danger">{errors.NIN}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        type="password"
                        name="Password"
                        className="input-text"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={Password}
                      />
                      {errors.password && (
                        <span className="text-danger">{errors.password}</span>
                      )}
                    </div>

                    <div className="form-group">
                      {Password && !checkPasswordPattern() && (
                        <p style={{ fontSize: "1em", color: "red" }}>
                          Password must be at least 8 characters long and
                          include at least one uppercase letter, one lowercase
                          letter, one number, and one special character.
                        </p>
                      )}
                      <p style={{ fontSize: "1em", color: "red" }}>{message}</p>
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn-md btn-theme btn-block"
                      >
                        {loading ? (
                          <div className="loading-container">
                            Login{" "}
                            <img
                              src={loadingIcon}
                              alt="Loading..."
                              style={styles.loadingImage}
                            />
                          </div>
                        ) : (
                          <>Login</>
                        )}
                      </button>
                    </div>
                    <div className="checkbox clearfix">
                      <div className="form-check checkbox-theme"></div>
                      <Link onClick={handleShowResetPassword}>
                        Forgot Password
                      </Link>
                    </div>
                  </form>
                  <Question_Modal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-----------------------------------------------ForgetPassword-*/}
      <Modal show={showResetPassword} onHide={handleCloseResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitPassword}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="20010547641550000123"
                value={NIN}
                onChange={(e) => setNIN(e.target.value)}
                maxLength={20}
                minLength={20}
                title="NIN Format: 20010547641550000123"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseResetPassword}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              {loading ? (
                <div className="loading-container">
                  Reset Password{" "}
                  <img
                    src={loadingIcon}
                    alt="Loading..."
                    style={styles.loadingImage}
                  />
                </div>
              ) : (
                <>Reset Password</>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*-----------------------------------------------Keynote-*/}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span className="text-primary">Keynote before you start:</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="col-sm-12">
            <ol>
              <li>
                Barua ya Utambulisho toka Serikali ya Mtaa/Kijiji au Shehia.
              </li>
              <li>
                Kwa waombaji waliopo Makambini, barua zao zipitie kwa Wakuu wa
                Kambi husika.
              </li>
              <li>Nakala ya Cheti cha Kuzaliwa.</li>
              <li>
                Namba au Kitambulisho cha Taifa (NIDA) au Kitambulisho cha
                Mzanzibari.
              </li>
              <li>Vyeti vya kuhitimu shule (Leaving Certificates).</li>
              <li>Vyeti vya Taaluma (Academic Certificates).</li>
              <li>Vyeti vingine vya Fani mbalimbali.</li>
              <li>Wasifu wa Mwombaji (CV).</li>
              <li>Picha Moja ya Pasipoti zenye rangi ya Bluu.</li>
              <li>
                Muombaji anatakiwa ku-scan nyaraka zote pamoja na barua ya
                maombi ya kazi, kila nyaraka isizidi ukubwa wa 500Kb.
              </li>
            </ol>
          </div>
        </Modal.Body>
      </Modal>
      <footer
        className="text-center text-secondary mt-auto p-0.5"
        style={{backgroundColor: "#040074" }}
      >
        <h4 style={{ fontSize: "1.1em", color: "white" }}>v0.2.6</h4>
      </footer>
    </>
  );
}

export default Login;
