import React from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import "../../includes/myStyles.css";
import AuthGuard from "../../utility/AuthGuard";

function Status() {
  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <br />
            <div class="col-sm-12">
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <div className="box-body panel-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <br />
                        <p style={{ fontSize: "23px", textAlign: "center" }}>
                          Successful Submited
                        </p>
                      </div>
                    </div>
                    <div className="row" style={{ textAlign: "center" }}>
                      <br />
                      <Link to="/home">
                        <button className="btn btn-default btn-label-next">
                          <i className="fa fa-home"> | </i>Go back to Home Page
                        </button>
                      </Link>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Status;
