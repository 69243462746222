import React, { useEffect, useState } from "react";
import Header_profile from "./Header_profile";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getMIddleName,
  getFirstName,
  getSurName,
  getGender,
  url,
  urlResource,
  getDateOfBirth,
  getNin,
  getPHOTO,
} from "../../utility/Common";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { createNotification } from "../Popup";
import AuthGuard from "../../utility/AuthGuard";  // Import AuthGuard HOC

function Personal_Info({ setIsAuthenticated }) {
  const FirstName = getFirstName();
  const MiddleName = getMIddleName();
  const LastName = getSurName();
  const Gender = getGender();
  const DateOfBirth = getDateOfBirth();
  const NIN = getNin();
  const PHOTO = getPHOTO();

  const navigate = useNavigate();
  const [PhotoImage, setPhotoImage] = useState("");
  const [BirthCertificateImage, setBirthCertificateImage] = useState("");
  const [Territory, setTerritory] = useState("");
  const [District, setDistrict] = useState("");
  const [WardID, setWardID] = useState("");
  const [StreetName, setStreetName] = useState("");
  const [TerritoryData, setTerritoryData] = useState([]);
  const [RegionData, setRegionData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  const [WardData, setWardData] = useState([]);
  const [RegionByTerritory, setRegionByTerritory] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [PopNIN, setPopNIN] = useState(false);

  const handleShow = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  var md5 = require("md5");

  const checkPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(Password);
  };

  const Personal_Info = {
    SystemId: "UHM005",
    ResourceType: "InsertPersonalInfo",
    ManagementDataType: "Personal_Info",
    NIN,
    FirstName,
    MiddleName,
    LastName,
    DateOfBirth,
    Gender,
    BirthCertificateImage,
    PhotoImage,
    WardID,
    StreetName,
    Password: md5(Password),
  };

  //----------------------------------------submitFunction
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Password !== ConfirmPassword) {
      return setPasswordMatchError("Passwords do not match");
    }
    const personalInfo = getBase64String(Personal_Info);
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        personalInfo
      );
      const data = response?.data;
      const code = response?.data.code;
      if (code == 1) {
        setPopNIN(data?.data[0].NIN);
        handleShow();
      } else {
        var msg = response?.data.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------functionBase64String
  function getBase64String(Personal_Info) {
    const pdfDoc = Personal_Info.BirthCertificateImage;
    const base64String = pdfDoc.split(",").pop();
    Personal_Info.BirthCertificateImage = base64String;

    const imgDoc = Personal_Info.PhotoImage;
    const base64StringPhotoImage = imgDoc.split(",").pop();
    Personal_Info.PhotoImage = base64StringPhotoImage;
    return Personal_Info;
  }

  useEffect(() => {
    getterritories();
  }, []);

  //----------------------------------------territory
  function getterritories() {
    var payload = {
      actionid: "Resources",
      ResourceType: "Territory",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setTerritoryData(data);
        console.log(data);
      });
  }

  const TerritoryInputChange = (e) => {
    TerritoryData?.map((obj) => {
      var territoryId = obj.ResourceID;
      if (e.target.value == territoryId) {
        setTerritory(territoryId);
        getRegionHandler(territoryId);
      }
    });
  };

  //----------------------------------------Region
  function getRegionHandler(territoryId) {
    var payload = {
      actionid: "Resources",
      ResourceType: "RegionByTerritory",
      ResourceParam: territoryId,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setRegionData(data);
      });
  }

  const RegionInputChange = (e) => {
    RegionData?.map((obj) => {
      var RegionID = obj.ResourceID;
      if (e.target.value == RegionID) {
        setRegionByTerritory(RegionID);
        getDistrictHandler(RegionID);
      }
    });
  };

  //----------------------------------------District
  function getDistrictHandler(RegionID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "DistrictByRegion",
      ResourceParam: RegionID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setDistrictData(data);
      });
  }

  const DistrictInputChange = (e) => {
    DistrictData?.map((obj) => {
      var DistrictID = obj.ResourceID;
      if (e.target.value == DistrictID) {
        setDistrict(DistrictID);
        getWardHandler(DistrictID);
      }
    });
  };

  //----------------------------------------Ward
  function getWardHandler(DistrictID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "WardByDistrict",
      ResourceParam: DistrictID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setWardData(data);
      });
  }

  const WardInputChange = (e) => {
    WardData?.map((obj) => {
      var WardID = obj?.ResourceID;
      if (e.target.value == WardID) {
        setWardID(WardID);
      }
    });
  };

  //----------------------------------------Base64_PDF
  const handleFileChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage(
          "The file size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setBirthCertificateImage(base64File);
      };
    }
  };

  //----------------------------------------Base64_jpg/png
  const handlePhotoChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "image/jpeg") {
        setErrorMsg("Invalid file type. Please upload a JPG or JPEG file.");
        return;
      }
      if (selectedFile.size > 300000) {
        setErrorMsg(
          "The file size is too large. Please upload a file smaller than 300KB."
        );
        return;
      }
      setErrorMsg("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setPhotoImage(base64File);
      };
    }
  };

  //----------------------------------------BirthDate_Validation
  const [dateError, setDateError] = useState("");
  const handleDateChange = (e) => {
    const inputDate = new Date(e.target.value);
    const currentDate = new Date();
    const minDate = new Date(
      currentDate.getFullYear() - 33,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const maxDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    if (inputDate >= minDate && inputDate <= maxDate) {
      setDateError("");
    } else {
      setDateError("Tafadhali hakikisha umri wako ni kati ya miaka 18-33 tu");
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p
              className="text-default h3"
              style={{ fontFamily: "Yu Gothic Light" }}
            >
              Applicant Registration
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="text-default h4 text-center"
            style={{ fontFamily: "Yu Gothic Light" }}
          >
            Your Successfully Registered
          </p>
          <br />
          <p className="text-primary h4 text-center">
            Your NIN is:{""} <b>{PopNIN}</b>
          </p>
          <p
            className="text-default h4 text-center"
            style={{ fontFamily: "Yu Gothic Light" }}
          >
            Please record your username in order to login to recruitment portal.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Header_profile />

      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <br />
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 tab-registration-menu hidden-xs">
                <div className="list-group">
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary active"
                  >
                    Personal Information
                  </Link>
                  <Link to="#" className="list-group-item ">
                    Education Background
                  </Link>
                  <Link to="#" className="list-group-item ">
                    Other Profession
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <form id="step-one-form" onSubmit={handleSubmit}>
                    <input type="hidden" name="_csrf" value="" />

                    <div className="box box-default">
                      <div className="box-header with-border">
                        <h3 className="box-title">Personal Information</h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group field-FirstName">
                              <label
                                htmlFor="FirstName"
                                className="control-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="FirstName"
                                value={FirstName}
                                disabled
                                readOnly
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-MiddleName">
                              <label
                                htmlFor="MiddleName"
                                className="control-label"
                              >
                                Middle Name
                              </label>
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="MiddleName"
                                value={MiddleName}
                                aria-required="true"
                                disabled
                                readOnly
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-LastName">
                              <label
                                htmlFor="LastName"
                                className="control-label"
                              >
                                Surname
                              </label>
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="LastName"
                                value={LastName}
                                disabled
                                readOnly
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group field-Gender">
                              <label htmlFor="Gender" className="control-label">
                                Gender
                              </label>
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="Gender"
                                value={Gender}
                                disabled
                                readOnly
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-field-DateOfBirth.">
                              <label
                                htmlFor="DateOfBirth"
                                className="control-label"
                              >
                                Date of Birth
                              </label>
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="DateOfBirth"
                                value={DateOfBirth}
                                disabled
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-NIN. required">
                              <label htmlFor="NIN" className="control-label">
                                National ID Number(NIDA)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="NIN"
                                value={NIN}
                                disabled
                                readOnly
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group field-PhotoImage. required">
                              <label
                                htmlFor="PhotoImage"
                                className="control-label"
                              >
                                Applicant Photo
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                name="PhotoImage"
                                accept="image/jpeg, image/png" // Restrict to JPEG and PNG image files
                                onChange={handlePhotoChanges}
                                required
                              />
                              {errorMsg && (
                                <p style={{ color: "red" }}>{errorMsg}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-field-BirthCertificateImage. required">
                              <label
                                htmlFor="BirthCertificateImage"
                                className="control-label"
                              >
                                Birth Certificate
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                name="BirthCertificateImage"
                                accept=".pdf" // Restrict to PDF files
                                onChange={handleFileChanges}
                                required
                              />
                              {errorMessage && (
                                <p style={{ color: "red" }}>{errorMessage}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box box-default">
                      <div className="box-header with-border">
                        <h3 className="box-title">Residential Adress</h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group field-Territory required">
                              <label
                                htmlFor="Territory"
                                className="control-label"
                              >
                                Residence
                              </label>
                              <select
                                id="TerritoryID"
                                name="Territory"
                                className="form-control text-uppercase"
                                value={Territory}
                                onChange={(e) => TerritoryInputChange(e)}
                                required
                              >
                                <option>Select ..</option>
                                {TerritoryData?.map((obj) => {
                                  return (
                                    <option
                                      key={obj.ResourceID}
                                      value={obj.ResourceID}
                                    >
                                      {obj.ResourceName}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group field-Region required">
                              <label htmlFor="Region" className="control-label">
                                Region
                              </label>
                              <select
                                id="RegionID"
                                name="RegionByTerritory"
                                className="form-control text-uppercase"
                                value={RegionByTerritory}
                                onChange={(e) => RegionInputChange(e)}
                                required
                              >
                                <option>Select ..</option>
                                {RegionData?.map((obj) => {
                                  return (
                                    <option
                                      key={obj.ResourceID}
                                      value={obj.ResourceID}
                                    >
                                      {obj.ResourceName}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-District. required">
                              <label
                                htmlFor="District"
                                className="control-label"
                              >
                                District
                              </label>
                              <select
                                id="DistrictID"
                                name="District"
                                className="form-control text-uppercase"
                                onChange={(e) => DistrictInputChange(e)}
                                value={District}
                                required
                              >
                                <option>Select ..</option>
                                {DistrictData?.map((obj) => {
                                  return (
                                    <option
                                      key={obj.ResourceID}
                                      value={obj.ResourceID}
                                    >
                                      {obj.ResourceName}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group field-Ward required">
                              <label htmlFor="Ward" className="control-label">
                                Ward/Shehia
                              </label>
                              <select
                                id="WardID"
                                name="WardByDistrict"
                                className="form-control text-uppercase"
                                onChange={(e) => WardInputChange(e)}
                                value={WardID}
                                required
                              >
                                <option>Select ..</option>
                                {WardData?.map((obj) => {
                                  return (
                                    <option
                                      key={obj.ResourceID}
                                      value={obj.ResourceID}
                                    >
                                      {obj.ResourceName}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-field-checkNo. required">
                              <label
                                htmlFor="StreetName"
                                className="control-label"
                              >
                                Village/Street
                              </label>
                              <input
                                type="text"
                                id=""
                                className="form-control text-uppercase"
                                name="StreetName"
                                onChange={(e) => setStreetName(e.target.value)}
                                value={StreetName}
                                required
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box box-default">
                      <div className="box-header with-border">
                        <h3 className="box-title">Enter your Password</h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group field-MembershipNo required">
                              <label
                                htmlFor="StreetName"
                                className="control-label"
                              >
                                Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                name="Password"
                                placeholder="Password"
                                value={Password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group field-MembershipNo required">
                              <label
                                htmlFor="StreetName"
                                className="control-label"
                              >
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                name="ConfirmPassword"
                                placeholder="Confirm Password"
                                value={ConfirmPassword}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                                required
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <br />
                            {Password && !checkPasswordPattern() && (
                              <p style={{ color: "red" }}>
                                Password must be at least 8 characters long and
                                include at least one uppercase letter, one
                                lowercase letter, one number, and one special
                                character.
                              </p>
                            )}

                            {passwordMatchError && (
                              <p style={{ color: "red" }}>
                                {passwordMatchError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box-footer">
                      <div className="row">
                        <div className="col-md-4">
                          <Link
                            to="#"
                            className="btn btn-labeled btn-block btn-default"
                            name="SaveExit"
                            onClick={refreshPage}
                          >
                            Clear |{" "}
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                          </Link>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button
                            type="submit"
                            className="btn btn-default btn-block btn-label-next"
                          >
                            Next | <i className="fa fa-angle-double-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Personal_Info;
