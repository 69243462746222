import React, { useState } from "react";
import Header_profile from "./Header_profile";
import { useNavigate } from "react-router-dom";
import {
  url,
  getNin,
  getEmail
} from "../../utility/Common";
import axios from "axios";
import { createNotification } from "../Popup";
import AuthGuard from "../../utility/AuthGuard";  // Import AuthGuard HOC

function Email_VerificationResponse() {
  const NIN = getNin();
  const Email = getEmail();
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState("");

  //----------------------------------------submitFunction
  const Personal_Info = {
    SystemId: "UHM005",
    ManagementDataType: "EmailValidity",
    ResourceType: "verifyTokeForEmail",
    verificationCode,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        url() + "erms_verifyvalidemail",
        Personal_Info
      );
      const code = response?.data?.code;
      if (code == 1) {        
        var msg = response?.data.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
        navigate("/educationbackg");
      }         
    } catch (error) {
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  return (
    <>
      <Header_profile />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <br />
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <form id="form" onSubmit={handleSubmit}>
                    <div className="box box-default">
                      <div className="box-header with-border">
                        <h3 className="box-title">Verify your Email</h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group required">
                              <label
                                htmlFor="StreetName"
                                className="control-label"
                              >
                                Email Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="Email"
                                value={Email}
                                disabled
                                readonly
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group required">
                              <label
                                htmlFor="StreetName"
                                className="control-label"
                              >
                                Verification Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="verificationCode"
                                onChange={(e) =>
                                  setVerificationCode(e.target.value)
                                }
                                value={verificationCode}
                                required
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-footer">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2">
                          <button
                            type="submit"
                            className="btn btn-default btn-block btn-label-next"
                          >
                            Verify |{" "}
                            <i className="fa fa-angle-double-right"></i>
                          </button>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Email_VerificationResponse;
