import React, { useEffect, useState } from "react";
import Header_Reset from "./Header_Reset";
import { useLocation } from "react-router-dom";
import { url, setResetToken } from "../../utility/Common";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { createNotification } from "../Popup";
import loadingIcon from "../../img/loadingElement.gif";

function Verification() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("sesseionId");
    verifyToken(sessionId);
  }, [location]);

  const verifyToken = async (sessionId) => {
    setIsLoading(true);
    const payload = {
      SystemId: "UHM005",
      ManagementDataType: "ForgetPassword",
      ResourceType: "verifyTokenCode",
      Token: sessionId,
    };
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        payload
      );
      setIsLoading(false);
      const { code } = response?.data;
      if (code == "1") {
        setResetToken(sessionId);
        navigate("/forgetpassword");
      } else {
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
        navigate("/");
      }
    } catch (error) {
      navigate("/");
      setIsLoading(false);
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  /*-------------Styles--------------*/
  const styles = {
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingImage: {
      width: "70px",
      height: "70px",
    },
  };

  return (
    <>
      <Header_Reset />
      <br />
      <br />
      {isLoading ? (
        <div style={styles.loadingContainer}>
          <img src={loadingIcon} alt="Loading..." style={styles.loadingImage} />
        </div>
      ) : (
        <div style={styles.loadingContainer}>
          <p>Data Loaded</p>
        </div>
      )}
    </>
  );
}
export default Verification;
