import React from "react";
import "../../includes/css/template.css";
import "../../includes/assets/5aa1fa4e/css/font-awesome.min.css";
import "../../includes/assets/40b83da1/css/bootstrap.css";
import "../../includes/css/loginCopy.css";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer"; //npm i react-idle-timer
import { remSessionLogout, removeUserInfo } from "../../utility/Common";
import coat from "../../includes/images/coat.png";
import immigration from "../../includes/images/immigration.png";

function Header_Profile() {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeUserInfo();
    remSessionLogout();
    navigate("/");
  };

  //auto session logout
  const handleOnIdle = (event) => {
    // alert("This session has already been expired. Please login again.");
    handleLogout();
  };
  const handleOnActive = (event) => {
    // console.log('user is active', event)
  };
  const handleOnAction = (event) => {
    // console.log('user did something', event)
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  <script type="text/javascript">
    function preventBack() {window.history.forward()}
    setTimeout(preventBack, 0) window.onunload = function () {null}
  </script>;

  return (
    <>
      <div class="row">
        <div class="col-md-1 col-sm-1 col-xs-1 vertical-align text-left hidden-xs"></div>
        <div class="col-md-10 col-sm-10 col-xs-12 vertical-align text-center d-flex flex-column align-items-center justify-content-center">
          <div class="d-flex align-items-center">
            <img
              src={coat}
              alt="Left Image"
              class="logo-image"
              style={{
                width: "60px",
                height: "60px",
              }}
            />
            <h3
              class="m-b-1"
              style={{
                fontSize: "2em",
                fontFamily: "Yu Gothic Light",
                margin: "0 100px",
              }}
            >
              Tanzania Immigration Services Department
            </h3>
            <img
              src={immigration}
              alt="Right Image"
              class="logo-image"
              style={{
                width: "60px",
                height: "60px",
              }}
            />
          </div>
        </div>
      </div>
      <div class="logoBar2">
        <div class="container">
          <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-1 vertical-align text-left hidden-xs"></div>
            <div class="col-md-10 col-sm-10 col-xs-12 vertical-align text-center">
              <h3
                class="m-b-1"
                style={{ fontSize: "3em", fontFamily: "Yu Gothic" }}
              >
                RECRUITMENT PORTAL
              </h3>
            </div>
          </div>
        </div>
      </div>
      <ul
        class="ul2"
        style={{
          color: "white",
          class: "btn",
        }}
      >
        <li class="li2">&nbsp;</li>
      </ul>
    </>
  );
}

export default Header_Profile;
