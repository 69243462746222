import React, { useState } from "react";
import Header_Reset from "./Header_Reset";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { createNotification } from "../Popup";
import { url, getResetToken } from "../../utility/Common";
import AuthGuard from "../../utility/AuthGuard";

function ResetPassword() {
  const navigate = useNavigate();
  var md5 = require("md5");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");

  const checkPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(NewPassword);
  };

  //-------------------------------------------ForgetPassword
  const Login = {
    SystemId: "UHM005",
    ManagementDataType: "ForgetPassword",
    ResourceType: "resetForgetPassword",
    NewPassword: md5(NewPassword),
    Token: getResetToken(),
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (NewPassword !== ConfirmPassword) {
      return setPasswordMatchError("Passwords do not match");
    }
    const token = getResetToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        Login,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { code } = response?.data;
      if (code == "1") {
        setNewPassword(NewPassword);
        navigate("/");
      } else {
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };
  return (
    <>
      <Header_Reset />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <br />
              <br />
              <div className="tab-registration-content active">
                <form id="step-one-form" onSubmit={handleSubmit}>
                  <input type="hidden" name="_csrf" value="" />
                  <div className="box box-default">
                    <div className="box-header with-border">
                      <h3 className="box-title">Reset Password</h3>
                    </div>
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group field-MembershipNo required">
                            <label
                              htmlFor="StreetName"
                              className="control-label"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="NewPassword"
                              placeholder="New Password"
                              value={NewPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group field-MembershipNo required">
                            <label
                              htmlFor="StreetName"
                              className="control-label"
                            >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="ConfirmPassword"
                              placeholder="Confirm Password"
                              value={ConfirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              required
                            />
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <br />
                          {NewPassword && !checkPasswordPattern() && (
                            <p style={{ color: "red" }}>
                              Password must be at least 8 characters long and
                              include at least one uppercase letter, one
                              lowercase letter, one number, and one special
                              character.
                            </p>
                          )}
                          {passwordMatchError && (
                            <p style={{ color: "red" }}>{passwordMatchError}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-2"></div>
                      <div className="col-md-2"></div>
                      <div className="col-md-2">
                        <button
                          type="submit"
                          className="btn btn-default btn-block btn-label-next"
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;