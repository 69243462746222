import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Personal_Info from "./components/profile/Personal_Info";
import EducationBackg from "./components/profile/EducationBackg";
import Professionals from "./components/profile/Professionals";
import NewApplication from "./components/apply/NewApplication";
import UpdateNewApplication from "./components/apply/update/UpdateNewApplication";
import ApplicationAttachment from "./components/apply/ApplicationAttachment";
import Status from "./components/apply/Status";
import Professional_Upload from "./components/profile/Professional_Upload";
import Submition from "./components/profile/Submition";
import Submit_Appl from "./components/apply/Submit_Appl";
import Declaration from "./components/apply/Declaration";
import ForgetPassword from "./components/profile/ForgetPassword";
import Verification from "./components/profile/Verification";
import Email_Verification from "./components/profile/Email_Verification";
import Email_VerificationResponse from "./components/profile/Email_VerificationResponse";
import { NotificationContainer } from "react-notifications";
import ProtectedRoute from "./utility/ProtectedRoute"; // Import ProtectedRoute

function useBlockNavigation(navigate) {
  useEffect(() => {
    const unblock = navigate.block((tx) => {
      if (window.confirm("Are you sure you want to leave this page?")) {
        unblock(); // Allow navigation
        tx.retry(); // Retry the navigation
      }
    });
    return () => {
      unblock(); // Cleanup when the component unmounts
    };
  }, [navigate]);
}

function PreventBackNavigation() {
  useEffect(() => {
    // Push current state to history to disable back button
    window.history.pushState(null, '', window.location.href);

    // Prevent back navigation
    const handlePopState = () => {
      window.history.pushState(null, '', window.location.href);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
}

function App() {
  // Initialize the authentication state from localStorage or default to false
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => JSON.parse(localStorage.getItem("isAuthenticated")) || false
  ); 

  useEffect(() => {
    window.scrollTo(0, 1);
    const handleResize = () => {
      window.scrollTo(0, 1);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update localStorage whenever isAuthenticated state changes
  useEffect(() => {
    localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  return (
    <div className="App">
      <NotificationContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route exact path="/personalinfo" element={<Personal_Info setIsAuthenticated={setIsAuthenticated} />} />
          <Route exact path="/home" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Home /></ProtectedRoute>} />
          <Route exact path="/educationbackg" element={<ProtectedRoute isAuthenticated={isAuthenticated}><EducationBackg /></ProtectedRoute>} />
          <Route exact path="/professionals" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Professionals /></ProtectedRoute>} />
          <Route exact path="/newapplication" element={<ProtectedRoute isAuthenticated={isAuthenticated}><NewApplication /></ProtectedRoute>} />
          <Route exact path="/updatenewapplication" element={<ProtectedRoute isAuthenticated={isAuthenticated}><UpdateNewApplication /></ProtectedRoute>} />
          <Route exact path="/applicationattachment" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ApplicationAttachment /></ProtectedRoute>} />
          <Route exact path="/status" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Status /></ProtectedRoute>} />
          <Route exact path="/professionalupload" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Professional_Upload /></ProtectedRoute>} />
          <Route exact path="/submition" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Submition /></ProtectedRoute>} />
          <Route exact path="/submitappl" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Submit_Appl /></ProtectedRoute>} />
          <Route exact path="/declaration" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Declaration /></ProtectedRoute>} />
          <Route exact path="/forgetpassword" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ForgetPassword /></ProtectedRoute>} />
          <Route exact path="/verification" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Verification /></ProtectedRoute>} />
          <Route exact path="/emailverification" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Email_Verification /></ProtectedRoute>} />
          <Route exact path="/emailverificationresponse" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Email_VerificationResponse /></ProtectedRoute>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
