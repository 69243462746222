import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfo, url, getAjiraToken } from "../../utility/Common";
import { createNotification } from "../Popup";
import CallForInterviewPDF from "../PDF/CallForInterviewPDF";
import loadingIcon from "../../img/loadingElement.gif";

function NavSection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userNotification, setUserNotification] = useState("");
  const [AppStageID, setAppStageID] = useState("");
  const [LevelName, setLevelName] = useState("");
  const [IsKoz, setIsKoz] = useState(null);
  const [JoinInstructionPDF, setJoinInstructionPDF] = useState("");

  const handleButtonApply = () => {
    if (!LevelName) {
      navigate("/educationbackg");
    } else {
      if (!AppStageID) {
        navigate("/newapplication");
      } else if (AppStageID === 210) {
        navigate("/applicationattachment");
      } else if (AppStageID === 220) {
        navigate("/declaration");
      }
    }
  };

  useEffect(() => {
    const fetchStatus = () => {
      setLoading(true);
      const payload = {
        SystemId: "UHM005",
        AccessorId: getUserInfo()?.ApplicantID,
        ManagementDataType: "Status",
        ResourceType: "CheckStatus",
        Username: getUserInfo()?.NIN,
      };
      const token = getAjiraToken();
  
      fetch(url() + "erms_applicationmanagement", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((response) => {
          const data = response?.data?.[0];
          
          if (data) {
            setUserNotification(data?.UserNotification || "");
            setAppStageID(data?.ApplicationStageID || "");
            setLevelName(data?.LevelName || "");
            setIsKoz(data?.IsKoz ?? null);
            setJoinInstructionPDF(data?.JoinInstructionPDF || "");
          } else {
            // Handle case where data is missing
            createNotification({
              type: "warning",
              message: "No data available for the current user.",
            })();
          }
        })
        .catch((error) => {
          const msg =
            error?.message || "An error occurred. Please try again later.";
          createNotification({ type: "error", message: msg })();
        })
        .finally(() => {
          setLoading(false);
        });
    };
  
    fetchStatus();
  }, []);
  

  const handleButtonProfile = () => {
    navigate("/educationbackg");
  };

  /*-------------Styles--------------*/
  const styles = {
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingImage: {
      width: "30px",
      height: "30px",
    },
  };

  return (
    <>
      <div className="row">
        <br />
        <div className="col-sm-12">
          <section id="what-we-do">
            <div className="row mt-5">
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  {loading ? (
                    <div className="loading-container">
                      <img
                        src={loadingIcon}
                        alt="Loading..."
                        style={styles.loadingImage}
                      />
                    </div>
                  ) : (
                    <>
                      {IsKoz === 0 ? (
                        <div className="card-block block-7">
                          <h3 className="card-title page-header m-reset-0">
                            Your Profile
                          </h3>
                          <a
                            href="#"
                            className="card-title m-reset-0"
                            onClick={handleButtonProfile}
                          >
                            Update Profile{" "}
                            <i className="ml-2 fa fa-angle-double-right"></i>
                          </a>
                        </div>
                      ) : (
                        <>
                          {IsKoz !== 0 && (
                            <div className="text-secondary card-title m-reset-0">
                              You have successfully applied for an employment
                              post. You can continue to visit your profile to
                              see the status of your application.
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  {loading ? (
                    <div className="loading-container">
                      <img
                        src={loadingIcon}
                        alt="Loading..."
                        style={styles.loadingImage}
                      />
                    </div>
                  ) : (
                    <>
                      {IsKoz === 0 ? (
                        <div className="card-block block-7">
                          <h3 className="card-title page-header m-reset-0">
                            Job Application
                          </h3>
                          <a
                            href="#"
                            className="card-title m-reset-0"
                            onClick={handleButtonApply}
                          >
                            Start Application{" "}
                            <i className="ml-2 fa fa-angle-double-right"></i>
                          </a>
                        </div>
                      ) : (
                        <>
                          {IsKoz !== 0 && (
                            <div className="text-secondary card-title m-reset-0">
                              {/* <i className="ml-2 fa fa-close"></i> */}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  {loading ? (
                    <div className="loading-container">
                      <img
                        src={loadingIcon}
                        alt="Loading..."
                        style={styles.loadingImage}
                      />
                    </div>
                  ) : (
                    <div className="card-block block-3">
                      <h3 className="card-title page-header m-reset-0">
                        Check Status
                      </h3>
                      <div className="card-title m-reset-0 text-danger">
                        <>
                          {userNotification}
                          {IsKoz === 2 && (
                            <div className="mt-2">
                              <Link to="#" onClick={() => setIsKoz(true)}>
                                Call for Interview
                              </Link>
                              {IsKoz && <CallForInterviewPDF />}
                            </div>
                          )}
                          {IsKoz === 3 && (
                            <div className="mt-2">
                              <Link to="#" onClick={() => setIsKoz(true)}>
                                Joining Instructions
                              </Link>
                              {IsKoz && <JoinInstructionPDF />}
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      </div>
    </>
  );
}

export default NavSection;
