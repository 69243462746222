import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Question_image from "../images/nin-questions.png";
import "./questionsmodal.css";
import {
  url,
  setMIddleName,
  setFirstName,
  setSurName,
  setGender,
  setDateOfBirth,
  setNin,
  setPHOTO,
} from "../../utility/Common";
import { RiSearchLine } from "react-icons/ri";
import { createNotification } from "../Popup";

const Question_Modal = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [question, setQuestion] = useState("");
  const [rqCode, setRQcode] = useState("");
  const [errors, setErrors] = useState({});
  const [errors1, setErrors1] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [nida, setNida] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setForm1({ ...form1, NIN: "" });
    setForm({ ...form, Answer: "" });
  };
  const handleShow = () => {
    setShow(true);
  };
  const [active, setActive] = useState(0);
  function changeStates() {
    setActive(0);
  }
  function changeState() {
    setActive(1);
  }
  function registerButtn() {
    handleShow();
    changeStates();
  }

  const [form1, setForm1] = React.useState({
    NIN: "",
    SystemId: "UHM005",
    ManagementDataType: "NidaVerification",
    ResourceType: "FirstRequest",
  });

  const { NIN } = form1;
  const handleInputChange1 = (e) => {
    setForm1({ ...form1, [e.target.name]: e.target.value });
  };
  const [form, setForm] = React.useState({
    Answer: "",
    SystemId: "UHM005",
    ManagementDataType: "NidaVerification",
    RQCode: rqCode,
    NIN: "",
    ResourceType: "NidaAnswer",
    REQUEST_ID: `${Date.now()}`,
  });
  const { Answer } = form;
  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validateForm1 = () => {
    let isValid = true;
    const newErrors1 = {};
    const isValidNIN = /^\d+$/.test(form1.NIN);
    if (!form1.NIN || form1.NIN.trim() == "" || !isValidNIN) {
      const info = {
        type: "error",
        message: "Please enter a valid NIN number",
      };
      createNotification(info)();
      isValid = false;
    }
    setErrors1(newErrors1);
    return isValid;
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!form.Answer || form.Answer.trim() == "") {
      newErrors.Answer = "Please enter Your Answer";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const NidaRequest = () => {
    if (validateForm1()) {
      setIsLoading(true);
      axios
        .post(url() + "erms_nida", form1)
        .then((response) => {
          setIsLoading(false);
          setErrors("");
          const data = response;
          const code = response.data.code;
          if (code == 1) {
            setQuestion(
              response.data.data.NidaResponse.EN +
                "(" +
                response.data.data.NidaResponse.SW +
                ")"
            );
            setMessage(response.data.message);
            setForm1({ ...form1, NIN: response.data.data.NidaResponse.NIN });
            setForm({ ...form, NIN: form1.NIN });
            setRQcode(response.data.data.NidaResponse.RQCode, () => {
              setForm({
                ...form,
                RQCode: response.data.data.NidaResponse.RQCode,
              });
            });
            setActive(2);
          } else if (response.data.code == 0) {
            const info = { type: "error", message: response.data.message };
            createNotification(info)();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          const msg = err.message;
          const info = { type: "err", message: msg };
          createNotification(info)();
        });
    } else {
      //alert('Form validation failed');
    }
  };

  useEffect(() => {
    setForm({ ...form, RQCode: rqCode });
  }, [rqCode]);

  useEffect(() => {
    setForm1({ ...form1, NIN: nida });
  }, [nida]);

  const HandleRequest = () => {
    setQuestion("");
    setMessage("");
    setForm1({ ...form1, NIN: "" });
    if (validateForm()) {
      setIsLoading(true);
      axios
        .post(url() + "erms_nida", form)
        .then((response) => {
          setIsLoading(false);
          const data = response;
          const code = response.data.code;
          setForm({ ...form, Answer: "" });
          if (code == 1) {
            setQuestion(
              response.data.data.NidaResponse.EN +
                "(" +
                response.data.data.NidaResponse.SW +
                ")"
            );
            setMessage(response.data.message);
            setRQcode(response.data.data.NidaResponse.RQCode, () => {
              setForm({
                ...form,
                RQCode: response.data.data.NidaResponse.RQCode,
              });
            });
            setActive(2);
          } else if (code == 2) {
            setFirstName(response.data.data.FIRSTNAME);
            setMIddleName(response.data.data.MIDDLENAME);
            setSurName(response.data.data.SURNAME);
            setGender(response.data.data.SEX);
            setDateOfBirth(response.data.data.DATEOFBIRTH);
            setNin(response.data.data.NIN);
            setPHOTO(response.data.data.PHOTO);
            setQuestion(response.data.data.FIRSTNAME + "" + response.data.data.SURNAME);
            setMessage(response.data.message);
            navigate("/personalinfo");
          } else if (response.data.code == 0) {
            setActive(3);
            setMessage(response.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          const msg = err.message;
          const info = { type: "err", message: msg };
          createNotification(info)();
        });
    } else {
      setMessage(message);
      setQuestion(question);
    }
  };

  return (
    <>
      {" "}
      <Link
        onClick={registerButtn}
        style={{
          flex: 1,
          marginRight: "5px",
          fontSize: "15px",
          textDecoration: "none",
        }}
      >
        Have Profile? <b>Register Here!</b>
      </Link>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={Question_image}
              alt="questions"
              class="questionimage"
              width="50px"
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoading ? (
            <p
              style={{
                fontSize: "17px",
                textAlign: "center",
                marginTop: "50px",
              }}
            >
              Loading...
            </p>
          ) : (
            <>
              {active == 0 ? (
                <>
                  <div class="box" onClick={changeState}>
                    <div class="details">
                      <h5 class="header5">Questions</h5>
                      <p
                        className="text-default h4 text-center"
                        style={{ fontFamily: "Yu Gothic Light" }}
                      >
                        You will be asked questions according to the information
                        you provided during your <b>NIN</b> Application.
                        <br />
                        <br />
                        <i className="text-primary">Click here</i>
                      </p>
                    </div>
                  </div>
                </>
              ) : active == 1 ? (
                <>
                  <div className="form-group">
                    <label
                      for="inputNIN"
                      class="control-label p-0"
                      id="inputNIN"
                    >
                      Enter Your NIN Number
                    </label>
                    <label htmlFor="name">{question}</label>
                    <input
                      className="form-control rounded-0"
                      name="NIN"
                      value={NIN}
                      onChange={handleInputChange1}
                      maxLength={20}
                      minLength={20}
                    />
                    {errors1.NIN && (
                      <p
                        className="queserror p-2"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {errors1.NIN}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary smallButton centerButton"
                      type="submit"
                      onClick={NidaRequest}
                    >
                      <RiSearchLine size={20} /> Search
                    </button>
                  </div>
                </>
              ) : active == 2 ? (
                <>
                  <div className="form-group">
                    <label
                      for="inputNIN"
                      class="control-label p-2"
                      id="inputNIN"
                    >
                      {message}
                    </label>
                    <label htmlFor="name">{question}</label>
                    <input
                      className="form-control rounded-0 smallInput"
                      id="Answer"
                      name="Answer"
                      value={Answer}
                      onChange={handleInputChange}
                    />
                    {errors.Answer && (
                      <p
                        className="queserror p-2"
                        style={{
                          color: "red",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {errors.Answer}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary smallButton centerButton"
                      type="submit"
                      onClick={HandleRequest}
                    >
                      Next | <i className="fa fa-angle-double-right"></i>{" "}
                    </button>
                  </div>
                </>
              ) : active == 3 ? (
                <>
                  <div class="box">
                    <div class="details">
                      <h5 class="headerfailed">{message}</h5>
                      <p
                        className="failedquestion text-center text-default h4"
                        style={{ fontFamily: "Yu Gothic Light" }}
                      >
                        You are not allowed to apply
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Question_Modal;