import React, { useState } from "react";
import Header_profile from "./Header_profile";
import { useNavigate } from "react-router-dom";
import { url, getNin } from "../../utility/Common";
import axios from "axios";
import { createNotification } from "../Popup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import loadingIcon from "../../img/loadingElement.gif";
import AuthGuard from "../../utility/AuthGuard";  // Import AuthGuard HOC

function Email_Verification() {
  const NIN = getNin();
  const navigate = useNavigate();
  const [Email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  //----------------------------------------submitFunction
  const Personal_Info = {
    SystemId: "UHM005",
    ResourceType: "getVerificationToken",
    ManagementDataType: "EmailValidity",
    NIN,
    Email,
    phoneNumber,
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        url() + "erms_verifyvalidemail",
        Personal_Info
      );
      const code = response?.data?.code;
      if (code == 1) {
        setEmail(response?.data);
        const msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
        navigate("/emailverificationresponse");
      } else {
        const msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };
  

  /*-------------Styles--------------*/
  const styles = {
    linkContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingImage: {
      width: "20px",
      height: "20px",
    },
  };

  return (
    <>
      <Header_profile />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <br />

              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-registration-menu hidden-xs"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <form id="step-one-form" onSubmit={handleSubmit}>
                    <div className="box box-default">
                      <div className="box-header with-border">
                        <h3 className="box-title">Verify Your Email</h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group required">
                              <label
                                htmlFor="StreetName"
                                className="control-label"
                              >
                                NIN
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="NIN"
                                value={NIN}
                                disabled
                                readonly
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group required">
                              <label
                                htmlFor="StreetName"
                                className="control-label"
                              >
                                Email Address
                              </label>
                              <input
                                type="email"
                                id=""
                                className="form-control"
                                title="Email Format: ajira@gmail.com"
                                placeholder="ajira@gmail.com"
                                name="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={Email}
                                required
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group required">
                              <label
                                className="control-label"
                                for="passportbasicdetails-mobileno"
                              >
                                Phone Number
                              </label>
                              <PhoneInput
                                country={"tz"}
                                enableSearch={true}
                                id="phoneNumber"
                                name="phoneNumber"
                                onChange={(phoneNumber) =>
                                  setPhoneNumber(phoneNumber)
                                }
                                value={phoneNumber}
                                required
                                inputStyle={{
                                  width: "100%",
                                  borderRadius: ".2rem",
                                }}
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-footer">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2">
                          <button
                            type="submit"
                            className="btn btn-default btn-block btn-label-next"
                          >
                            {loading ? (
                              <div className="loading-container">
                                Verify |{" "}
                                <i className="fa fa-angle-double-right"></i>{" "}
                                <img
                                  src={loadingIcon}
                                  alt="Loading..."
                                  style={styles.loadingImage}
                                />
                              </div>
                            ) : (
                              <>Verify</>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Email_Verification;
